import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { AppLoader } from "../components/app-loader";
import { WEB_ROUTES } from "../routes/web-routes";
import TeamItem from "../components/team-item";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Impact() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);

  const [pageED, setPageED] = useState({});
  const [pageEDMedia, setPageEDMedia] = useState([]);

  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "impact"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "economic-development"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPageED(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageEDMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Third Way`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-white">
          <div className="container mx-auto">
            <div className="md:flex">
              <div className="md:w-1/2 w-full md:py-20 md:px-16 py-10 px-8 text-white bg-[#47188e]">
                <span className="font-medium text-[#8cccd1]">Impact</span>
                <h1 className="py-5 text-4xl font-medium">
                  {page?.title?.rendered}
                </h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered,
                  }}
                />
              </div>
              <div
                className="md:w-1/2 w-full bg-white bg-no-repeat bg-cover bg-center md:mt-16 mt-0 bg-none"
                style={{
                  backgroundImage: `url(${
                    pageMedia?.find(
                      (media) => media?.id === page?.featured_media
                    )?.source_url
                  })`,
                }}
              >
                {page?.featured_media ? (
                  <img
                    src={
                      pageMedia?.find(
                        (media) => media?.id === page?.featured_media
                      )?.source_url
                    }
                    className={`m-auto object-cover md:hidden visible`}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <section className="bg-[#270a51]">
          <div className="container mx-auto">
            <div className="md:flex">
              <div className="md:w-1/2 w-full py-10 md:py-16 md:px-16 px-8 bg-white">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.acf?.sub_section?.sub_section_overview,
                  }}
                />
              </div>
              <div className="md:w-1/2 w-full py-10 md:py-16  md:px-16 px-8 bg-[#bd4292] text-white">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.acf?.sub_section?.sub_section_approach,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="relative md:bg-[#270a51] bg-[#47188e]">
          <div className="container mx-auto text-white py-10 md:py-16 md:px-16 px-8">
            <h2
              className="text-4xl md:text-center font-medium mb-10"
              dangerouslySetInnerHTML={{
                __html: page?.acf?.key_components_header,
              }}
            />

            <div className="mx-auto md:flex mt-8 md:gap-x-8">
              <div className="bg-[#47188e] md:p-8 pb-8 md:w-1/3 w-full">
                <h3 className="md:text-white text-[#ffa8bd] font-medium mb-4 text-2xl">
                  Financing Clean Energy
                </h3>
                <p>
                  Third Way provides targeted debt financing to fund managers
                  investing in projects that reduce carbon emissions, create
                  jobs, and foster socio-economic and enterprise development
                  within South Africa. By focusing on renewable energy sources
                  such as solar, wind, and hydroelectric power, Third Way helps
                  reduce reliance on fossil fuels, cut greenhouse gas emissions,
                  and mitigate climate change. These projects also stimulate
                  economic growth and create employment opportunities,
                  particularly in underdeveloped and rural areas.
                </p>
              </div>

              <div className="bg-[#47188e] md:p-8 pb-8 md:w-1/3 w-full">
                <h3 className="font-medium md:text-white text-[#ffa8bd] mb-4 text-2xl">
                  Investment in Infrastructure
                </h3>
                <p>
                  Our infrastructure investment strategy supports high-quality
                  projects that drive economic growth and improve community
                  well-being. Prioritising sustainability and resilience, we
                  align with South Africa’s NDP by targeting critical sectors
                  such as energy, transportation, and social infrastructure. By
                  partnering with governments, private entities, and local
                  communities, we ensure that our projects deliver long-term
                  value and contribute to the NDP’s inclusive growth and
                  development goals.
                </p>
              </div>

              <div className="bg-[#47188e] md:p-8 pb-8 md:w-1/3 w-full">
                <h3 className="font-medium md:text-white text-[#ffa8bd] mb-4 text-2xl">
                  ESG Integration
                </h3>
                <p>
                  We integrate ESG issues into our investment analysis and
                  decision-making processes whilst adhering to local and
                  international standards. By practising rigorous due diligence
                  and continuously keeping our finger on the pulse, we ensure
                  our investments uphold responsible business conduct and
                  environmental stewardship across our portfolio.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="relative">
          <div className="container mx-auto text-white py-8 px-8">
            <h3 className="font-medium mb-4 text-4xl text-[#270a51]">
              Notable SDG Impacts
            </h3>
          </div>
        </section> */}

        <section className="relative md:pb-20 pb-0">
          <div className="absolute top-0 left-0 bg-[#270a51] w-1/2 h-full -z-10 md:block hidden"></div>

          <div className="container mx-auto">
            <div className="md:flex">
              <div className="md:w-1/2 w-full bg-[#270a51] text-white md:pt-16 md:pr-16 md:pb-28 py-10 px-8">
                <h2
                  className="font-medium mb-4 text-4xl"
                  dangerouslySetInnerHTML={{
                    __html: pageED?.title?.rendered,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageED?.content?.rendered,
                  }}
                />
                <div className="flex mt-10 content-start items-start gap-x-4">
                  <div className="font-medium">
                    <div className="bg-[#ffa8bd] rounded-full h-7 w-7 text-center">
                      1
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageED?.acf?.main_points?.main_point_1,
                    }}
                  />
                </div>
                <div className="flex mt-10 content-start items-start gap-x-4">
                  <div className="font-medium">
                    <div className="bg-[#ffa8bd] rounded-full h-7 w-7 text-center">
                      2
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageED?.acf?.main_points?.main_point_2,
                    }}
                  />
                </div>
                <div className="flex mt-10 content-start items-start gap-x-4">
                  <div className="font-medium">
                    <div className="bg-[#ffa8bd] rounded-full h-7 w-7 text-center">
                      3
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageED?.acf?.main_points?.main_point_3,
                    }}
                  />
                </div>
              </div>
              <div className="md:w-1/2 w-full py-10 md:pb-16 md:py-16 md:px-16 px-8 md:pt-0 text-left">
                <img
                  className="w-12/12 m-auto"
                  src={
                    pageEDMedia?.find(
                      (media) => media.id === pageED?.acf?.esg_report_image
                    )?.source_url
                  }
                  alt={pageED.title?.rendered}
                />
                {/* <a
                  href={
                    pageEDMedia?.find(
                      (media) => media.id === pageED?.acf?.esg_report_file
                    )?.source_url
                  }
                  target="_blank"
                  className="flex-none px-5 py-2 inline-block"
                >
                  Download the ESG Report <span aria-hidden="true">→</span>
                </a> */}
                <p className="mt-4 md:px-4">
                  Third Way is launching its inaugural Impact Report soon.
                  Subscribe to our mailing list for an exclusive notification.
                </p>
              </div>
            </div>
          </div>
          <div className="container mx-auto bg-[#47188e]">
            <div className="md:flex">
              <div className="md:w-1/2 w-full text-white md:py-16 py-10 pb-0 md:px-16 px-8">
                <h3
                  className="text-3xl"
                  dangerouslySetInnerHTML={{
                    __html: pageED?.acf?.commitment_header,
                  }}
                />
              </div>
              <div className="md:w-1/2 w-full text-white py-10 md:py-16 md:px-16 px-8">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageED?.acf?.commitment_content,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
